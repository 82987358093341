// utils
import get from "./get";

/**
 * Groupe un tableau par une valeur
 * @param {any[]} arr
 * @param {(item: any) => any} predicate
 * @returns {object}
 */
export default function groupBy(
  arr,
  predicate = function (item) {
    return get(item, "group", "?");
  },
) {
  /** @type {any} */
  const output = {};

  for (const item of arr) {
    let keys = predicate(item);
    if (!Array.isArray(keys)) {
      keys = [keys];
    }
    for (const key of keys) {
      if (!(key in output)) {
        output[key] = [];
      }
      output[key].push(item);
    }
  }

  return output;
}
