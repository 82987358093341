// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";

// components
import RoomRankingsRating from "./Rating";

// utils
import groupBy from "@cloudspire/shared/utils/groupBy";

// helpers
import {
  HOTEL_RATING_TYPE_VALUE_CITY_BREAK,
  HOTEL_RATING_TYPE_VALUE_EPI,
  HOTEL_RATING_TYPE_VALUE_KEY,
  HOTEL_RATING_TYPE_VALUE_STAR,
} from "@cloudspire/shared/constants/hotel/rating";

const styles = StyleSheet.create({
  rankings: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "1rem",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
  },
  main: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  secondary: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flexWrap: "wrap",
  },
});

type PropsType = {
  room: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >;
  color?: string;
  aCitybreakStyle?;
  shouldAddSearchLink?: boolean;
  shouldHideStar?: boolean;
  shouldSeparateMainRatings?: boolean;
};

const mainRatingTypes = [
  HOTEL_RATING_TYPE_VALUE_CITY_BREAK,
  HOTEL_RATING_TYPE_VALUE_EPI,
  HOTEL_RATING_TYPE_VALUE_KEY,
];

function RoomRankings(props: PropsType) {
  const {
    room,
    color,
    aCitybreakStyle,
    shouldAddSearchLink = false,
    shouldHideStar = false,
    shouldSeparateMainRatings = true,
  } = props;

  const ratings = (room.metas.ratings ?? []).filter(
    (rating) => rating.type !== HOTEL_RATING_TYPE_VALUE_STAR || !shouldHideStar
  );

  const ratingsGroupedByImportance = groupBy(ratings, (rating) =>
    mainRatingTypes.includes(rating.type) ? "main" : "secondary"
  );

  return (
    <div className={css(styles.rankings)}>
      {shouldSeparateMainRatings ? (
        <div className={css(styles.wrapper)}>
          {ratingsGroupedByImportance.main && (
            <div className={css(styles.main)}>
              {ratingsGroupedByImportance.main.map((rating) => (
                <RoomRankingsRating
                  key={rating.type}
                  rating={rating}
                  color={color}
                  aCitybreakStyle={aCitybreakStyle}
                  shouldAddSearchLink={shouldAddSearchLink}
                />
              ))}
            </div>
          )}
          {ratingsGroupedByImportance.secondary && (
            <div className={css(styles.secondary)}>
              {ratingsGroupedByImportance.secondary.map((rating) => (
                <RoomRankingsRating
                  key={rating.type}
                  rating={rating}
                  color={color}
                  aCitybreakStyle={aCitybreakStyle}
                  shouldAddSearchLink={shouldAddSearchLink}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        <>
          {ratings
            .filter(
              (rating) =>
                rating.type !== HOTEL_RATING_TYPE_VALUE_STAR || !shouldHideStar
            )
            .map((rating) => (
              <RoomRankingsRating
                key={rating.type}
                rating={rating}
                color={color}
                aCitybreakStyle={aCitybreakStyle}
                shouldAddSearchLink={shouldAddSearchLink}
              />
            ))}
        </>
      )}
    </div>
  );
}

export default RoomRankings;
