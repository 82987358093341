// deps
import React from "react";
import dynamic from "next/dynamic";

// components
import ActivityIndicator from "@cloudspire/legacy-resources/src/components/ActivityIndicator";
import {
  HOTEL_RATING_TYPE_VALUE_BIODIVERSITE,
  HOTEL_RATING_TYPE_VALUE_CITY_BREAK,
  HOTEL_RATING_TYPE_VALUE_ECOGESTE,
  HOTEL_RATING_TYPE_VALUE_ECOGITE,
  HOTEL_RATING_TYPE_VALUE_EPI,
  HOTEL_RATING_TYPE_VALUE_GITE_PANDA,
  HOTEL_RATING_TYPE_VALUE_KEY,
  HOTEL_RATING_TYPE_VALUE_STAR,
} from "@cloudspire/shared/constants/hotel/rating";

const Key = dynamic(() => import("./Key"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const Epi = dynamic(() => import("./Epi"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const CityBreak = dynamic(() => import("./CityBreak"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const Star = dynamic(() => import("./Star"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const Ecogite = dynamic(() => import("./Ecogite"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const GitePanda = dynamic(() => import("./GitePanda"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const Ecogeste = dynamic(() => import("./Ecogeste"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

const Biodiversite = dynamic(() => import("./Biodiversite"), {
  loading: () => <ActivityIndicator size={16 as any} />,
});

type PropsType = {
  rating: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >["metas"]["ratings"][0];
  color?: string;
  aCitybreakStyle?;
  shouldAddSearchLink?: boolean;
};

function RoomRankingsRating(props: PropsType) {
  const { rating, color, aCitybreakStyle, shouldAddSearchLink = false } = props;

  switch (rating.type) {
    case HOTEL_RATING_TYPE_VALUE_STAR:
      return <Star value={rating.value} />;
    case HOTEL_RATING_TYPE_VALUE_EPI:
      return (
        <Epi
          value={rating.value}
          color={color}
          shouldAddSearchLink={shouldAddSearchLink}
        />
      );
    case HOTEL_RATING_TYPE_VALUE_CITY_BREAK:
      return (
        <CityBreak
          value={rating.value}
          aStyle={aCitybreakStyle}
          shouldAddSearchLink={shouldAddSearchLink}
        />
      );
    case HOTEL_RATING_TYPE_VALUE_KEY:
      return (
        <Key value={rating.value} shouldAddSearchLink={shouldAddSearchLink} />
      );
    case HOTEL_RATING_TYPE_VALUE_ECOGITE:
      return <Ecogite />;
    case HOTEL_RATING_TYPE_VALUE_GITE_PANDA:
      return <GitePanda />;
    case HOTEL_RATING_TYPE_VALUE_ECOGESTE:
      return <Ecogeste />;
    case HOTEL_RATING_TYPE_VALUE_BIODIVERSITE:
      return <Biodiversite />;
    default:
      return null;
  }
}

export default RoomRankingsRating;
